<template>
    <div class="jkqbox">
        <div class="jkqcont">
            <div class="toptext">
                <img src="/img/jkq.png" alt="">
            </div>
            <div class="aboutjkq">
                <p>上饶经济技术开发区位于广信区中心城区西部，始建于2001年，2 0 1 0年1 1月升格为国家级经济技术开发区。经开区紧紧围绕“五年决战三千亿，挺进全国四十强”的经济发展目标努力打造世界光伏城、中国光学城、江西汽车城、江西北斗城、国际细胞谷。目前已形成了“3+2”的产业格局，即以晶科能源（全球最大晶硅光伏企业）为龙头的光伏产业，构建了除硅料生产外的完整主产业链；以凤凰光学（全球智慧安防设备销量第一中电海康的子公司）为龙头的光电产业，集聚了170余家光电中小微企业；以长城汽车、博 能 客 车 、 爱 驰 汽 车 、 吉 利 商 用 车 等 整 车 企 业 为 龙 头 的 汽 车 产业，已集聚核心零部件企业7 0余家；以干细胞存储、细胞及再生医学技术开发、药物生产为主导的生物医药产业，已集聚生物医药企业4 0余家；随着江西北斗城项目的落地，北斗产业将围绕技术协同创新、成果转移孵化、人才联合培养等方面，建立北斗领域的专业化生产研发基地。</p>
                <p>上饶经开区辖区代管广信区董团乡、兴园街道办及铅山县鹅湖镇的8个行政村，总面积207平方公里，总人口12.6万余人，其中产业工人6 . 3万人。经开区现有注册企业3 0 0 0余家，其中工业企业368家，规模以上企业206家，高新技术企业126家。经开区先后获批国家加工贸易梯度转移重点承接地、台资企业转移承接基地、国家级中国科协海智工作基地、国家光伏高新技术产业化基地、国家光学高新技术产业化基地、国家新能源汽车高新技术产业化基地，省生态工业园区、省级智能制造示范基地，省就业扶贫示范园区。</p>
            </div>
            <div class="qybox">
                <div class="qyitem" v-for="(item,index) in list" @click="$router.push({ path: '/user/show', query: { id: item.id } })">
                    <el-image
                    v-if="item.url"
                    style="width: 267px; height: 65px"
                    :src="item.url"
                    fit="contain"></el-image>
                    <span v-else>{{item.title}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import userRequest from "@/api/user";
export default {

    data(){
        return {
            page: 1,
            size: 40,
            total: 0,
            classlist: [],
            classid: 97,
            classname: "上饶经开区重点企业招聘",
            loading: false,
            list: [],
        };
    },
    created(){
        this.getlist()
    },
    methods:{
        getlist() {
        this.loading = true;
        userRequest
            .getnewsList({
            classId: this.classid,
            page: this.page,
            size: this.size,
            })
            .then((res) => {
            this.loading = false;
            console.log(res);
            this.list = res.data.list;
            this.total = res.data.total;
            });
        },
    }
}
</script>
<style scoped>
.jkqbox{
min-height: 600px;
background: url("/img/bg.jpg") no-repeat top center;
background-color: #ff9037;
padding-bottom: 100px;
}
.jkqcont{ width: 1300px; margin: 0 auto 0 auto;}
.toptext{ text-align: center; padding-top: 80px;}
.aboutjkq{ color:#fff; line-height: 160%; font-size: 16px; margin-top: 70px; text-indent: 2em;}
.qybox{ background: #ffffff;   box-sizing: border-box; padding: 20px; margin-top: 50px; display: flex; align-items: center; flex-wrap: wrap;}
.qyitem{ border:#e0e0e0 solid 1px; transition: border 0.3s; margin-right: 35px; margin-bottom:20px; text-align: center; box-sizing: border-box; padding: 0 10px; cursor: pointer; width: 287px;height: 100px; display: flex; align-items: center; justify-content: center;}
.qyitem:nth-child(4n){ margin-right: 0;}
.qyitem:hover{ border:#ff9037 solid 1px;}
.qyitem img{ height: 100%;}
.qyitem span{ font-size: 18px; line-height: 150%; font-weight: bold;}

</style>